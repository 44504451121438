exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admin-artists-tsx": () => import("./../../../src/pages/admin/artists.tsx" /* webpackChunkName: "component---src-pages-admin-artists-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-shows-tsx": () => import("./../../../src/pages/admin/shows.tsx" /* webpackChunkName: "component---src-pages-admin-shows-tsx" */),
  "component---src-pages-admin-venues-tsx": () => import("./../../../src/pages/admin/venues.tsx" /* webpackChunkName: "component---src-pages-admin-venues-tsx" */),
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contribute-tsx": () => import("./../../../src/pages/contribute.tsx" /* webpackChunkName: "component---src-pages-contribute-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-shows-tsx": () => import("./../../../src/pages/shows.tsx" /* webpackChunkName: "component---src-pages-shows-tsx" */),
  "component---src-pages-venues-tsx": () => import("./../../../src/pages/venues.tsx" /* webpackChunkName: "component---src-pages-venues-tsx" */),
  "component---src-templates-artist-page-tsx": () => import("./../../../src/templates/ArtistPage.tsx" /* webpackChunkName: "component---src-templates-artist-page-tsx" */),
  "component---src-templates-venue-page-tsx": () => import("./../../../src/templates/VenuePage.tsx" /* webpackChunkName: "component---src-templates-venue-page-tsx" */)
}

