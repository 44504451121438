import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import './src/styles/global.css';
import netlifyIdentity from 'netlify-identity-widget';
import { AdminDataProvider } from './src/contexts/AdminDataContext';

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  netlifyIdentity.init();

  setTimeout(function () {
    const loader = document.getElementById('___loader');
    if (loader) {
      loader.style.display = 'none';
    }
  }, 1);
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  setTimeout(function () {
    const loader = document.getElementById('___loader');
    if (loader) {
      loader.style.display = 'none';
    }
  }, 1);
};

export const onRouteUpdateDelayed: GatsbyBrowser['onRouteUpdateDelayed'] = () => {
  setTimeout(function () {
    const loader = document.getElementById('___loader');
    if (loader) {
      loader.style.display = 'flex';
    }
  }, 1);
};

// Wrap the root element with providers
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  // Check if we're in an admin route before rendering the provider
  const isAdminRoute =
    typeof window !== 'undefined' &&
    window.location.pathname.startsWith('/admin');

  // Only wrap with AdminDataProvider if we're in an admin route
  return isAdminRoute ? (
    <AdminDataProvider>{element}</AdminDataProvider>
  ) : (
    element
  );
};
